import toast from 'react-hot-toast';

const utils = {
    errorHandling: function(err) {
        if (
            typeof err === 'object' 
            && !Array.isArray(err) 
        ) {
            if(!err.response.data.errors) {
                console.log(err);
                toast.error('Ocurrió un problema. Por favor, contacta a soporte');
            }

            for(const error in err.response.data.errors) {
                toast.error(err.response.data.errors[error][0]);
            }
        } else {
            console.log(err);
            toast.error('Ocurrió un problema. Por favor, contacta a soporte');
        }
    }
}

export default utils;