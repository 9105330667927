import { ActionTypes } from '../constants/action-types';

export const setCondominiums = (condominiums) => {
    return {
        type: ActionTypes.GET_CONDOMINIUMS,
        payload: condominiums
    }
}

export const selectedCondominium = (condominium) => {
    return {
        type: ActionTypes.SELECTED_CONDOMINIUM,
        payload: condominium
    }
}