import '../../assets/css/style.css';
import styles from './dashboard.module.css';
import {Outlet} from 'react-router-dom';
import Sidebar from './sidebar';
import Navbar from './navbar';
import axios from '../../config/axios';
import utils from '../../utils/util';

function Dashboard(){

    return (
        <>
            <Navbar/>
            <Sidebar/>

            <div className={styles.main}>
                <Outlet/>
            </div>
            
        </>
    );
}

export default Dashboard;