import {DropdownButton, Dropdown, Form} from 'react-bootstrap';
import { useEffect, useState } from 'react'; 
import axios from '../../config/axios';
import { useNavigate } from "react-router-dom";
import styles from './dashboard.module.css';
import utils from '../../utils/util';
import {useSelector, useDispatch} from 'react-redux';
import {setCondominiums} from '../../state/actions/condominiumActions';
import {setUser} from '../../state/actions/userActions';

function Navbar(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const condominiums = useSelector((state)=>state.condominiums.allCondominiums);

    useEffect(()=>{
        getUser();
    }, []);

    const getUser = async () => {
        try {
            let user = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/user`);
            dispatch(setUser(user.data));

            let resultCondominiums = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admins/${user.data.id}/condominiums`);
            dispatch(setCondominiums(resultCondominiums.data.data));
            
        } catch (err) {
            console.log('ERROR ',err);
        }
    }

    const doLogout = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/logout`); 
            localStorage.removeItem("isAuthenticated");
            navigate("/");
        } catch (err) {
            utils.errorHandling(err);
        }
    }

    return(
        <div className={`${styles.navbar}`}>
            <strong>LOGOTIPO</strong>
            <div className={`${styles.navbarItem}`}>
                <div style={{'marginRight': '2rem'}}>
                    <Form.Select size="sm">
                        <option>Condominio</option>
                        {condominiums.length > 0 && (
                            condominiums.map((cond)=>
                                <option key={cond.id}>
                                    {cond.name}
                                </option>
                            )
                        )}
                    </Form.Select>
                </div>
                <div className={''}>
                    <span className={'link-white'} onClick={()=>{doLogout()}}>Cerrar sesión</span>
                </div>
            </div>
        </div>
    );
}

export default Navbar;