import { ActionTypes } from '../constants/action-types';

export const depositMoney = (amount) => {
    return {
        type: ActionTypes.DEPOSIT,
        payload: amount
    }
}

export const withdrawMoney = (amount) => {
    return {
        type: ActionTypes.WITHDRAW,
        payload: amount
    }
}