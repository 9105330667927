import { ActionTypes } from "../constants/action-types";

const initialState = {
    allCondominiums: []
};

export const condominiumReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.SET_CONDOMINIUMS:
            return {...state, allCondominiums: payload};
        default:
            return state;
    }
}