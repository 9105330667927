import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from './components/routes/ProtectedRoute';
import RestrictedRoute from './components/routes/RestrictedRoute';
import Dashboard from './components/dashboard/base';
import Login from './components/auth/login';
import ForgotPassword from './components/auth/forgot-password';
import Register from './components/auth/register';
import ResetPassword from './components/auth/reset-password';
import NewCondominium from './pages/condominium/newCondominium';
import ListCondominiums from './pages/condominium/listCondominiums';
import {store} from './state/store';


ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        {/* <Dashboard/> */}
        <Routes>
          <Route
            path="/"
            element={
              <RestrictedRoute>
                <Login/>
              </RestrictedRoute>
            }>
          </Route>
          <Route
            path="/forgot-password"
            element={
              <RestrictedRoute>
                <ForgotPassword/>
              </RestrictedRoute>
            }>
          </Route>
          <Route
            path="/password-reset/:token"
            element={
              <RestrictedRoute>
                <ResetPassword/>
              </RestrictedRoute>
            }>
          </Route>
          <Route
            path="/register"
            element={
              <RestrictedRoute>
                <Register/>
              </RestrictedRoute>
            }>
          </Route>
          <Route
            path="/dashboard/"
            element={
              <ProtectedRoute>
                <Dashboard/>
              </ProtectedRoute>
            }>
            <Route path="new" element={<NewCondominium/>} />
            <Route path="data" element={<ListCondominiums/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
