import {useState} from 'react';
import styles from './newCondominium.module.css';
import {Form, Button, Row, Col} from 'react-bootstrap';

function ListCondominiums() {
    const [disabled, setDisabled] = useState(true);

    const handleFormDisable = () => {
        setDisabled(!disabled);
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        handleFormDisable();
    }
 
    return (
        <div className={`${styles.formContainer}`}>
            <div className={`${styles.darkHeader}`}>
                <h4>DATOS DE CONDOMINIO</h4>
            </div>
            <div className={`${styles.formNewCondominium}`}>
            <Form onSubmit={handleFormSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>NOMBRE</Form.Label>
                        <Form.Control placeholder="Nombre" disabled={disabled}/>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>CALLE</Form.Label>
                        <Form.Control placeholder="Calle" disabled={disabled}/>
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridAddress1">
                        <Form.Label>COLONIA</Form.Label>
                        <Form.Control placeholder="Colonia" disabled={disabled}/>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress2">
                        <Form.Label>CODIGO POSTAL</Form.Label>
                        <Form.Control placeholder="Código Postal" disabled={disabled}/>
                    </Form.Group>
                </Row>

                <Row>
                    <Col className={'d-flex justify-content-end'}>
                    {!disabled && 
                    <Button variant="primary" onClick={handleFormDisable} className={'m-1'}>
                        CANCELAR
                    </Button>
                    }
                    <Button variant="primary" type="submit" className={'m-1'}>
                        {disabled ? 'EDITAR': 'GUARDAR'}
                    </Button>
                    </Col>
                </Row>
            </Form>
            </div>
        </div>
    );
}
 
export default ListCondominiums;