import React, { useState } from 'react';
import {Button, Form} from 'react-bootstrap';
import styles from './login.module.css';
import { Toaster } from 'react-hot-toast';
import axios from '../../config/axios';
import {Link, useNavigate} from 'react-router-dom';
import utils from '../../utils/util';

function Register(){
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    const doRegister = async (event) => {
        try {
            event.preventDefault();
            const form = event.currentTarget;
    
            if (!form.checkValidity()) {
                event.stopPropagation();
            } else {
                await axios.get(`${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`);
                
                await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/register`, 
                { 
                    email: form.elements.email.value, 
                    password: form.elements.password.value, 
                    name: form.elements.name.value, 
                    password_confirmation: form.elements.confirmPassword.value, 
                    role: 'administrador' 
                });

                localStorage.setItem('isAuthenticated', true);
                navigate('/dashboard');
            }
            setValidated(true);
        } catch (err) {
            utils.errorHandling(err);
        }
    }

    return (
        <div className={styles.authcontainer}>
            <Toaster/>
            <div className={styles.title}>
                ADMINISTRADOR <br/> DEL CONDOMINIO
            </div>
            <div className={'bg-light mt-4'}>
                <div className={'bg-teal-admin px-3 py-2 text-white d-flex justify-content-center'}>
                    REGISTRO
                </div>
                <Form noValidate validated={validated} onSubmit={doRegister} className={'d-flex flex-column justify-content-center px-3 py-2'}>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Control type="text" placeholder="Nombre" required/>
                        <Form.Control.Feedback type="invalid">
                            Por favor proporciona tu nombre.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="email">
                        <Form.Control type="email" placeholder="Correo" required/>
                        <Form.Control.Feedback type="invalid">
                            Por favor proporciona un correo.
                        </Form.Control.Feedback>
                    </Form.Group>
                
                    <Form.Group className="mb-3" controlId="password">
                        <Form.Control type="password" placeholder="Contraseña" required/>
                        <Form.Control.Feedback type="invalid">
                            Por favor proporciona una contraseña.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="confirmPassword">
                        <Form.Control type="password" placeholder="Confirmar contraseña" required/>
                        <Form.Control.Feedback type="invalid">
                            Por favor confirma la contraseña.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" bsPrefix={styles.btnteal}>
                        Ingresar
                    </Button>
                </Form>
            </div>
            <span className={'text-white mt-3'}>
                ¿Ya tienes una cuenta? <Link className={'link'} to="/">Inicia Sesión</Link>
            </span>
        </div>
    );
}

export default Register;