import React from 'react';
import styles from './newCondominium.module.css';
import {Form, Button, Row, Col} from 'react-bootstrap';
import axios from '../../config/axios';
import utils from '../../utils/util';
import toast, { Toaster } from 'react-hot-toast';
import {useSelector, useDispatch} from 'react-redux';
import {setCondominiums} from '../../state/actions/condominiumActions';

function NewCondominium() {
    const dispatch = useDispatch();
    const user = useSelector((state)=>state.user);

    const clearForm = () => { 
        document.getElementById("create_condominium_form").reset();
    }

    const registerCondominium = async (event) => {
        try {
            event.preventDefault();
            const form = event.currentTarget;

            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/condominiums`, 
                { name: form.elements.name.value, 
                    street_name: form.elements.street_name.value,
                    neighborhood: form.elements.neighborhood.value ,
                    postal_code: form.elements.postal_code.value 
                }
            );
            toast.success('Se ha agregado un nuevo condominio');
            clearForm();
            await fetchCondominiums();
        } catch (err) {
            utils.errorHandling(err);
        }
    }


    const fetchCondominiums = async() => {
        let condominiums = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admins/${user.id}/condominiums`);
        dispatch(setCondominiums(condominiums.data.data));
    }
 
    return (
        <div className={`${styles.formContainer}`}>
            <Toaster/>
            <div className={`${styles.darkHeader}`}>
                <h4>AGREGAR CONDOMINIO</h4>
            </div>
            <div className={`${styles.formNewCondominium}`}>
            <Form onSubmit={registerCondominium} id="create_condominium_form">
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="name">
                    <Form.Label>NOMBRE</Form.Label>
                    <Form.Control placeholder="Nombre" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="street_name">
                    <Form.Label>CALLE</Form.Label>
                    <Form.Control placeholder="Calle" />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="neighborhood">
                        <Form.Label>COLONIA</Form.Label>
                        <Form.Control placeholder="Colonia" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="postal_code">
                        <Form.Label>CODIGO POSTAL</Form.Label>
                        <Form.Control placeholder="100" />
                    </Form.Group>
                </Row>

                <Row>
                    <Col className={'d-flex justify-content-end'}>
                        <Button variant="primary" type="submit">
                            Guardar
                        </Button>
                    </Col>
                </Row>
            </Form>
            </div>
        </div>
    );
}
 
export default NewCondominium;