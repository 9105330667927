import axios from 'axios'

const instance = axios.create({
    withCredentials: true
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if(!error.response) {
      alert('HA OCURRIDO UN PROBLEMA');
    } else {
      if (error.response.status === 401) {
        localStorage.removeItem("isAuthenticated");
        window.location = '/';
      }
      return Promise.reject(error);
    }
  }
);
export default instance;
