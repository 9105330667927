import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import styles from './login.module.css';
import '../../assets/css/colors.css';
import { useParams } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import axios from '../../config/axios';
import {useNavigate} from 'react-router-dom';
import utils from '../../utils/util';

const ResetPassword = () => {
    const { token } = useParams();
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    
    const doResetPassword = async (event) => {
        try {
            event.preventDefault();
            const form = event.currentTarget;
    
            if (!form.checkValidity()) {
                event.stopPropagation();
            } else {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/reset-password`, 
                { 
                    email: form.elements.password.value,
                    token,
                    password: form.elements.password.value,
                    password_confirmation: form.elements.confirmPassword.value
                }); 

                localStorage.setItem('isAuthenticated', true);
                navigate('/dashboard');
            }
            setValidated(true);   
        } catch (err) {
            utils.errorHandling(err);
        }
    }

  return (
        <div className={styles.authcontainer}>
            <Toaster/>
            <div className={styles.title}>
                    ADMINISTRADOR <br/> DEL CONDOMINIO
            </div>
            <div className={'bg-light mt-4'}>
                <div className={'bg-teal-admin px-3 py-2 text-white d-flex justify-content-center'}>
                    RESETEAR CONTRASEÑA
                </div>
                <Form noValidate validated={validated} onSubmit={doResetPassword} className={'d-flex flex-column justify-content-center px-3 py-2'}>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Control type="email" placeholder="Correo" required/>
                        <Form.Control.Feedback type="invalid">
                            Por favor proporciona tu correo.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="password">
                        <Form.Control type="password" placeholder="Contraseña" required/>
                        <Form.Control.Feedback type="invalid">
                            Por favor proporciona tu nueva contraseña.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="confirmPassword">
                        <Form.Control type="password" placeholder="Confirma contraseña" required/>
                        <Form.Control.Feedback type="invalid">
                            Por favor confirma tu nueva contraseña.
                        </Form.Control.Feedback>
                    </Form.Group>
                
                    <Button type="submit" bsPrefix={styles.btnteal}>
                        Enviar
                    </Button>
                </Form>
            </div>
        </div>
  );
}
 
export default ResetPassword;