import React from "react";
import {NavLink} from 'react-router-dom';
import styles from './sidebar.module.css';

function Sidebar() {
    return (
        <div className={styles.sidebar}>
            <div className={styles.avatar}>
                <p>ADMINISTRADOR DE CONDOMINIO</p>
                <p>Nombre del administrador</p>
            </div>
            <div className={`${styles.menuSection} ${styles.admins}`}>
                <strong>CONDOMINIO</strong>
                <ul>
                    <li>
                        <NavLink to='/dashboard/data'>
                            Ver datos
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/dashboard/new'>
                            Agregar nuevo
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div className={`${styles.menuSection} ${styles.condominios}`}>
                <strong>APARTAMENTOS</strong>
                <ul>
                    <li>
                        <NavLink to='/dashboard/data'>
                            Ver todos
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/dashboard/new'>
                            Agregar nuevo
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>          
    );
  };
  export default Sidebar;