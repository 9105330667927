import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import styles from './login.module.css';
import '../../assets/css/colors.css';
import toast, { Toaster } from 'react-hot-toast';
import {Link} from 'react-router-dom';
import axios from '../../config/axios';
import utils from '../../utils/util';

function ForgotPassword() {
    const [validated, setValidated] = useState(false);

    const doForgotPass = async (event) => {
        try {
            event.preventDefault();
            const form = event.currentTarget;
    
            if (!form.checkValidity()) {
                event.stopPropagation();
            } else {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/forgot-password`, 
                { email: form.elements.email.value });
    
                toast.success('Se ha enviado un correo para reestablecer tu contraseña');
            }
            setValidated(true);
        } catch(err) {
            utils.errorHandling(err);
        }
    }

  return (
    <div className={styles.authcontainer}>
        <Toaster/>
        <div className={styles.title}>
            ADMINISTRADOR <br/> DEL CONDOMINIO
        </div>
        <div className={'bg-light mt-4'}>
            <div className={'bg-teal-admin px-3 py-2 text-white d-flex justify-content-center'}>
                RECUPERAR CONTRASEÑA
            </div>
            <Form noValidate validated={validated} onSubmit={doForgotPass} className={'d-flex flex-column justify-content-center px-3 py-2'}>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Control type="email" placeholder="Correo" required/>
                    <Form.Control.Feedback type="invalid">
                        Por favor proporciona tu correo.
                    </Form.Control.Feedback>
                </Form.Group>
            
                <Button type="submit" bsPrefix={styles.btnteal}>
                    Enviar
                </Button>
            </Form>
        </div>
        <Link className={'link'} to="/">Inicia Sesión</Link>
    </div>
  );
}
 
export default ForgotPassword;