import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import styles from './login.module.css';
import '../../assets/css/colors.css';
import '../../assets/css/style.css';
import { Toaster } from 'react-hot-toast';
import axios from '../../config/axios';
import {Link, useNavigate} from 'react-router-dom';
import utils from '../../utils/util';
import {useSelector, useDispatch} from 'react-redux';
import { depositMoney } from '../../state/actions/accountActions';

function Login() {
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    const state = useSelector((state)=>state);
    console.log('state es:',state);
    const dispatch = useDispatch();

    const testaction = () => {
        console.log('test action');
        dispatch(depositMoney(3));
    }

    const doLogin = async (event) => {
        try {
            event.preventDefault();
            const form = event.currentTarget;
    
            if (!form.checkValidity()) {
                event.stopPropagation();
            } else {
                await axios.get(`${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`);

                let loginResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/login`, 
                    { email: form.elements.email.value, 
                      password: form.elements.password.value 
                    }
                );

                console.log('loginResponse', loginResponse);

                localStorage.setItem('isAuthenticated', true);
                navigate('/dashboard');
            }
            setValidated(true);
        } catch (err) {
            utils.errorHandling(err);
        }
    }

  return (
    <div className={styles.authcontainer}>
        <Toaster/>
        <div className={styles.title}>
            ADMINISTRADOR <br/> DEL CONDOMINIO
            <button onClick={() => {testaction()}}>ejemplo actions</button>
        </div>
        <div className={'bg-light mt-4'}>
            <div className={'bg-teal-admin px-3 py-2 text-white d-flex justify-content-center'}>
                BIENVENIDO
            </div>
            <Form noValidate validated={validated} onSubmit={doLogin} className={'d-flex flex-column justify-content-center px-3 py-2'}>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Control type="email" placeholder="Correo" required/>
                    <Form.Control.Feedback type="invalid">
                        Por favor proporciona un correo valido.
                    </Form.Control.Feedback>
                </Form.Group>
            
                <Form.Group className="mb-3" controlId="password">
                    <Form.Control type="password" placeholder="Contraseña" required/>
                    <Form.Control.Feedback type="invalid">
                        Por favor proporciona una contraseña.
                    </Form.Control.Feedback>
                </Form.Group>
                <Button type="submit" bsPrefix={styles.btnteal} >
                    Ingresar
                </Button>
            </Form>
        </div>
        <span className={'text-white mt-3'}>
            ¿No tienes una cuenta? <Link className={'link'} to="/register">Registrate</Link>
        </span>
        
        <Link className={'link'} to="/forgot-password">¿Has olvidado tu contraseña?</Link>
    </div>
  );
}
 
export default Login;