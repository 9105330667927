import { combineReducers } from "redux";
import accountReducer from './accountReducer';
import { condominiumReducer } from './condominiumReducer';
import { userReducer } from './userReducer';

const reducers = combineReducers({
    acccount: accountReducer,
    condominiums: condominiumReducer,
    user: userReducer
});

export default reducers;